import React from 'react'
import { rgba } from 'polished'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import Image from 'src/components/Image'
import Grid from 'src/components/Grid'
import Slideshow from 'src/components/Slideshow'
import TextLockup from 'src/components/TextLockup'
import { colors, mq, util, typography } from 'src/styles'
import { themes } from 'src/styles/themes'
import Img from 'gatsby-image'
import { IoMdClose } from 'react-icons/io'
import { withShopifyContext } from 'src/state/ShopifyState'

const CloseButton = styled(Button)`
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 15;
  z-index: 200;
  color: ${ ({ textColor }) => textColor ? colors[textColor] : colors.offwhite } !important;
  &:hover {
    transform: rotate(180deg);
  }
  ${ mq.mediumAndBelow } {
    background: ${ colors.offwhite };
    .button-content {
      color: ${ colors.textColor };
    }
  }
  color: ${ colors.black };
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const Close = styled(IoMdClose)`
`

const Modal = styled.div`
  position: fixed;
  overflow-x: hidden;
  max-width: 100%;
  background: ${ ({ backgroundColor }) => {
    return (backgroundColor && themes[backgroundColor] && themes[backgroundColor].background) || colors.offwhite
  } };
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`

const ProductImage = styled(Img)`
  width: 100%;
  object-fit: cover;
  height: 100%;
  background: ${ colors.lightGrey };
`
const SlideshowContainer = styled.div`
  min-height: 50vh;
  cursor: pointer;
  position: relative;
  width: 100%;
  > div {
    height: 100%;
  }
  ${ mq.mediumAndUp } {
    position: sticky;
    top: 0;
  }
  .slick-arrow {
    ${ mq.mediumAndBelow } {
      display: none !important;
      opacity: 0;
      pointer-events: none;
    }
    ${ mq.largeAndUp } {
      height: 85%;
      width: 50%;
      transform: none;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      svg {
        display: none;
      }
      &.slick-next {
        z-index: 1;
        right: 0;
        top: 0;
        bottom: 0;
        &:hover {
          cursor: -webkit-image-set(url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAAAjCAYAAAAt4qxQAAAABGdBTUEAALGPC/xhBQAAAVZJREFUaAXl2rFOAkEQBuCZO6ItnU/AE2BxBYlga2NCYU+B0h08AOppz7U8hAWllZQ0xAfwCXgFEm7cLUwgBLjb3QTzz5Sbnc3Ol9liN0sEEqP3QRKilDjEIudeY5Q99QopZknnur74Wn767CfySf4vuQZjRcRrEknT7HHisy+IDlnMlz9Ju/lNzF2D0kpu3TsFAsR2RCgUGJBQKFAgIVDgQHxRIEF8UGBBXFGgQVxQ4EGqoqgAqYKiBqQsiiqQMijqQE6h8DAb3Ahv2naivuAGCz2ISM1cDPN8PB1y+tZ/poJe9GHsV8zE9zWWeC7RRinIbodMxtMZ7zvpGElf+3fmmHyY95PLv+NiK1cJcghDJcgxDHUgpzBUgZTBUANSFkMFSBUMeJCqGNAgLhiwIK4YkCA+GHAgvhhQICEwYEBCYVgQiO8QEUdXRHKxfWu1xamOUD+IfgHWzSU4T01eJQAAAABJRU5ErkJggg==") 1x, url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIgAAABGCAYAAAAXWNyWAAAABGdBTUEAALGPC/xhBQAAAylJREFUeAHt3L9qFFEYBfC5yYLlVhYLVuIz2NhYCWmEPEIghKiNwTphYgpLexHyCAFLG+1s9A3E1sJKrJR1rjPB4E2yM3v/f+fDkyLZnbkzc/Y7Pybb7DYNfziBiQmYiX3cJTCBZ8ePtjrTPZwfLp60pu0EIly65MalZ3wiOoEBx2/TnVlr978///q6ta14P7yDiJL4d/GDk70H1po3jbU3LraaxpzOjxa7kncScaEXw/jv/85mX3oc39w52MbuSN9JeAdxGxF+fPDi8R37a/muj3HLjSJ5JyEQtwmAx2hICAQAxdUISEgI5Go7IM9RkBAICIhVMRCQEMiqZoC2SSMhECAMY1EkkRDIWCtg26WQEAgYhKk4EkgIZKoRwH21kRAIIIJ1kWoiIZB1bYDur4WEQEAB+MSqgYRAfJoAXlMaCYEAl+8brSQSAvFtAXxdKSQEAl58SLwSSAgkpAEFa3MjIRAFpYdGzImEQEKnr2R9LiQEoqTwmJg5kBBIzOQVHZOKhEAUlR0bNQUJgcROXdlxsUgIRFnRKXFjkBBIysQVHhuKhEAUlpwaOQQJgaROW+nxvkjOgTw93rNKXydjF5iA+1ngWYHz85TKJ/D3WwWa/vtJdvn1D8rLLB2fQEpPWOH53X8xfJOqsMAckYPepOa4IM+hZwK+OIZXxDuInl6zJA3BQSBZRq7nJKE4CERPt8lJY3AQSPLYdZwgFgeB6Og3KWUKDgJJGj3+wak4CAS/4+iEOXAQSPT4sQ/MhYNAsHuOSpcTB4FEVYB7UG4cBILbdXCyEjgIJLgGzANK4SAQzL6DUpXEQSBBVeAtLo2DQPA6905UAweBeNeBtbAWDgLB6t0rTU0cBOJVCc6i2jgIBKf7tUkkcBDI2lowFkjhIBCM/idTSOIgkMlq5HdK4yAQeQOjCRBwEMhoPbI7UHAQiKyDlVdHwkEgKyuS24iGg0DkLFy7MiKOISQ/3X+tKqENy+Xtxpib7tXdT9m722s+JpCa05641svDV2837cZ2j+TnsAwBx5Bjc/jFH4wJfHj/8fO9+3c/9Tp+zI8W+61pO4xkTMEJjEzgD4DNUenwE4jGAAAAAElFTkSuQmCC") 2x) 33 16, auto;
        }
      }
      &.slick-prev {
        z-index: 1;
        left: 0;
        top: 0;
        bottom: 0;
        &:hover {
          cursor: -webkit-image-set(url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAAAjCAYAAAAt4qxQAAAABGdBTUEAALGPC/xhBQAAAWBJREFUaAXt2j1uwjAUB/D3gtSZjYO0Q4YOFSdA4gYM/dgo3dsK2MlKb9CpU4dOZapYUA/QE3AEENi1gSAQCdgxQ+S/PSSD4ye/X5zItkx0htLpP8RnCFOKEJFrL9rdu4EQy59O977lGqsM7Z1ANAZJ2SbiuZBiWoaEXPvARQNsMZhnCqWZvLx9Fo1VpnaFQHzF0C/GGsRnDGsQ3zGsQBAwjEFQMIxAkDBOgqBhHAVBxMgFQcXIBEHGOABBx9gDCRiaYzN138FYqMn8O5H8W1djXVlWRvzUu20sBX1gpZ6TbUSvq8VdGCFroNUISa12ULza30jzM71X0gfH35OvuH5VVZs918TcjG8uf8ejCdy/ZAuiYQIK0R5IQMkAQUc5GCEaRBfUzycXBBXlKAgiykkQNBQjECQUYxAUFCsQBBRrEN9RCoH4jOJ0HCJ5Hj6qhWCiNpQuIo5qGioUJeDTCaJ/dPBLI7QMhWgAAAAASUVORK5CYII=") 1x, url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIgAAABGCAYAAAAXWNyWAAAABGdBTUEAALGPC/xhBQAAAyZJREFUeAHt3K+OFEEQBvDp2Q0WhcBi0BgMBiSGhEcgQYBGIY5bOE0Ww5FsSHgEEgzJKRyGRyAokhMo5CXHNtkTk9vdmZ7+U9VVNfeh7na6e2q+75dj1TQN/iGBQAKzwLVqlxZ+0d6Z3T6+d//u+vu3Hz+r3Rg3Gk2gHV3BvGCD4++b04/e+2f/3Przi9fPHzLfEscnJOAS1pIv7XA0/kl3uHNnzvlHy4PVSfcZfhBLQOwvSC+OTQze/2nm819iieDGWwmI/AUZxNE0v921+YPly2N8D9mqSe6X6kCAQ67snDtXBQIcORXJ7qkGBDhki869exUgwJFbj/w+diDAIV9yyQSsQICjpBode9mAAIeOgkunYAECHKW16NlPDgQ49JRLMQkpEOCgqETXGWRAgENXsVTTkAABDqo69J1TDAQ49JVKOVEREOCgrELnWdlAgENnodRTZQEBDuoa9J6XDAQ49JbJMVkSEODgqED3mdFAgEN3kVzTRQEBDq749Z87CgQ49JfIOWEQCHBwRm/j7EEgwGGjQO4pe4EAB3fsds7fAwIcdsqrMekWEOCoEbmte3RAgMNWcbWmvQASwFFrDtxHYQLvDleuBQ6FzSgaSez1D4oywCiBBNqFW6yvv7r51DXuU2AdLl3RBPAl9YoWH/vYHZDNhsD3EbzYJTbRia3bAgIkE2uX4HH2gAAJQaoTOqIXCJBMqOHCRxkEAiSFyU5kexAIkEyk5YLHGAUCJAXpTmBrFBAgmUDTmY8QDQRIMhM2vi0JCJAYbztj/GQgQJKRsuEtWUCAxHDjiaNnAwGSxKSNLi8CAiRGW08YuxgIkCSkbXApCRAgMdh85MhkQIAkMnFjy0iBAImx9iPGJQcCJBGpG1rCAgRIDAkYGZUNCJCMJG/kMisQIDGiIDAmOxAgCaRv4FIVIEBiQMLAiNWAAMlAA8o/rgoESJRr6BmvOhAg6WlB8UciQIBEsYid0cRe/zD4VgHnbjTn57d25sSvQgmIAdk87x4S585mvn28PFidCOWB2+4kIPZfzOU5Lt4qcHT6vvXtl7eHH75evoafkQASUJzAf4GGykMD/oNQAAAAAElFTkSuQmCC") 2x) 33 16, auto;
        }
      }
    }
  }
  .slick-dots {
    mix-blend-mode: difference;
    z-index: 1;
    bottom: 25px;
    text-align: right;
    padding-right: 25px;
    width: 30%;
    right: 0;
    li {
      margin: 0;
      button {
        position: relative;
        font-size: 0;
        text-indent: -20px;
        overflow: hidden;
        &:hover {
          &:before {
            opacity: 1;
          }
        }
        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          margin: -3px;
          color: white;
          content: '';
          display: block;
          font-size: 12px;
          transition: transform .3s ease-in-out, opacity .3s ease-in-out;
          opacity: .5;
          width: 6px;
          height: 6px;
          background: white;
          transform: scale(.5);
        }
      }
    }
    li.slick-active button:before {
      transform: scale(1);
      opacity: 1;
    }
    ${ mq.largeAndBelow } {
      display: none !important;
    }
  }
  ${ mq.largeAndUp } {
    height: 100vh;
    min-height: 100px;
    .slick-slider {
        height: 100vh;
        min-height: 100px;
        .slick-list {
            .slick-track {
                .slick-slide > div {
                    height: 100vh;
                    min-height: 100px;
                    display: flex;
                    background-size: cover;
                    background-repeat: no-repeat;
                    min-height: 100px;
                    img {
                      object-fit: cover;
                      min-height: 100px;
                    }
                }
            }
        }
    }
  }
`

const Overlay = styled.div`
  width: 100%;
  min-height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  top: 0;
  left: 0;
  ${ mq.mediumAndBelow } {
    height: auto;
    position: relative;
    min-height: 66vw;
  }
`

const ButtonContainer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 15;
`

const TextLockupStyled = styled(TextLockup)`
  color: ${ ({ textColor }) => colors[textColor || 'offwhite'] };
  ${ util.responsiveStyles('margin-top', 80, 42, 48, 50) }
  ${ util.responsiveStyles('margin-bottom', 100, 56, 52, 52) }
  h1, h2, h3 {
    margin-top: 0;
  }
  ul {
    margin-top: 1em;
    li {
      margin: 0;
      p {
        margin: 0;
      }
    }
  }
`

const FabricInfo = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 50vh;
  .gatsby-image-wrapper {
    background: ${ colors.lightGrey };
  }
  ${ mq.mediumAndBelow } {
    height: auto;
    min-height: unset;
  }
  .image-wrapper {
    ${ mq.mediumAndBelow } {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`

const BackgroundImage = styled(Image)`
  ${ ({ overlay }) => overlay === 'dark' || overlay === 'light' ? `
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: ${ overlay === 'dark' ? rgba(colors.black, 0.25) : rgba(colors.offwhite, 0.25) };
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const PlaceholderImage = styled.div`
  background: ${ colors.lightGrey };
  height: 100%;
  min-height: 50vh;
`

const GridStyled = styled(Grid)`
  min-height: 100%;
`

const MobileSlideCount = styled.div`
  ${ typography.body }
  font-weight: 500;
  color: ${ colors.white };
  mix-blend-mode: difference;
  height: 40px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: auto !important;
  ${ mq.largerAndUp } {
    display: none;
  }
`

class FabricOverlay extends React.Component {
  state = {
    currentSlideIndex: 0
  }

  afterChangeHandler = currentSlide => {
    this.setState({ currentSlideIndex: currentSlide })
  }

  render () {
    let { selectedFabric, className } = this.props

    if (!selectedFabric) return ''
    const { backgroundColor } = selectedFabric
    const { products, toggleFabric } = this.props
    const buttons = selectedFabric.associatedShopifyCollectionHandle ? [{
      to: `/shop/${ selectedFabric.associatedShopifyCollectionHandle }`,
      label: `Shop ${ selectedFabric.displayTitle }`,
      theme: selectedFabric.textColor || 'offwhite',
      style: 'button'
    }] : null

    const getResizedImage = image => {
      const resizeShopImage = this.props.shopifyContext.resizeShopImage
      return resizeShopImage(image, { maxWidth: 800, maxHeight: 800 })
    }

    const getSrcSet = image => {
      const resizeShopImage = this.props.shopifyContext.resizeShopImage
      const small = resizeShopImage(image, { maxWidth: 800, maxHeight: 800 })
      const medium = resizeShopImage(image, { maxWidth: 1000, maxHeight: 1000 })
      const large = resizeShopImage(image, { maxWidth: 1200, maxHeight: 1200 })
      const extraLarge = resizeShopImage(image, { maxWidth: 1500, maxHeight: 1500 })
      const huge = resizeShopImage(image, { maxWidth: 1800, maxHeight: 1800 })
      return (small + ' 800w, ' + medium + ' 1000w,' + large + ' 1200w,' + extraLarge + ' 1500w,' + huge + ' 1800w')
    }

    if (selectedFabric === 'loading') {
      return (
        <Modal backgroundColor={backgroundColor} className={className}>
          <CloseButton
            onClick={() => toggleFabric(null)}
            shape="circle"
            size="small"
            setTheme="transparent"
            textColor={selectedFabric.textColor}
            icon={<Close size={26} />}
          />
          <GridStyled
            small="[1]"
            medium="[1]"
            large="[1] [1]"
          >
            <PlaceholderImage/>
            <Overlay/>
          </GridStyled>
        </Modal>
      )
    }

    return (
      <Modal backgroundColor={backgroundColor} className={className}>
        <CloseButton
          onClick={() => toggleFabric(null)}
          shape="circle"
          size="small"
          setTheme="transparent"
          textColor={selectedFabric.textColor}
          icon={<Close size={26} />}
        />
        <GridStyled
          small="[1]"
          medium="[1]"
          large="[1] [1]"
        >
          <FabricInfo>
            <BackgroundImage
              overlay={selectedFabric && selectedFabric.imageOverlay}
              image={selectedFabric && selectedFabric.image}
              height="100%"
              sizes={'(max-width: ' + mq.mediumBreakpoint + 'px) 100vw, 50vw'}
            />
            <Overlay>
              <Grid
                small="1 [12] 1"
                medium="1 [4] 1"
                large="1 [4] 1"
              >
                {(products && products.length > 0) ? (
                  <TextLockupStyled
                    textColor={selectedFabric.textColor || 'offwhite'}
                    alignment="center"
                    headline={selectedFabric && selectedFabric.displayTitle}
                    headlineSize="h2"
                    headlineStyle="alternative"
                    text={selectedFabric && selectedFabric.text}
                    buttons={buttons}
                  />
                ) : ''}
              </Grid>
            </Overlay>
          </FabricInfo>
          {(!products || products.length <= 0) ? (
            <FabricInfo>
              <Overlay>
                <Grid
                  small="1 [12] 1"
                  medium="1 [4] 1"
                  large="1 [5] 1"
                  larger="1 [4] 1"
                >
                  <TextLockupStyled
                    textColor={selectedFabric.textColor || colors.textColor}
                    alignment="center"
                    headline={selectedFabric && selectedFabric.displayTitle}
                    headlineSize="h2"
                    headlineStyle="alternative"
                    text={selectedFabric && selectedFabric.text}
                    buttons={buttons}
                  />
                </Grid>
              </Overlay>
            </FabricInfo>
          ) : ''}

          {(products && products.length > 0) ? (
            <SlideshowContainer>
              <Slideshow
                showZoomButton={false}
                slides={products && products.map((product, index) => {
                  if (!product) return ''
                  const { images = [], handle, title } = product
                  return (
                    <div key={images && images[0].src + index}>
                      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                        <ProductImage
                          fluid={{
                            aspectRatio: 0.75,
                            src: getResizedImage(images[0]),
                            srcSet: getSrcSet(images[0]),
                            sizes: '(max-width: ' + mq.mediumBreakpoint + 'px) 50vw, 100vw'
                          }}
                        />
                        <ButtonContainer>
                          <Button
                            to={`/shop/${ handle }`}
                            internal
                            label={title}
                            setTheme="blackSolidAndOffwhite"
                          />
                        </ButtonContainer>
                      </div>
                    </div>
                  )
                })}
                afterChange={this.afterChangeHandler}
              />
              <MobileSlideCount>{this.state.currentSlideIndex + 1} / {products.length}</MobileSlideCount>
            </SlideshowContainer>
          )
            : ''}
        </GridStyled>
      </Modal>
    )
  }
}

export default withShopifyContext(FabricOverlay)
